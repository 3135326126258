import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Hidden from '../../../components/hidden';
import { MenuSide, Typography, Fab, paletteData } from '../../../components/styles';
import { GridContainer, GridCell } from '../../../components/grid';

import ConsoleMenuPanel from './consoleMenu';
import MyProfilePanel from './myProfile';
import ManageUsersPanel from './manageUsers';
import ManageOrganisationsPanel from './manageOrganisations';
import ManageAreasPanel from './manageAreas';
import ManageCategoriesPanel from './manageCategories';
import ManageArticlesPanel from './manageArticles';
import ManageProductsPanel from './manageProducts';
import ManageOrdersPanel from './manageOrders';
import ManageBookingCalendarsPanel from './manageBookingCalendars'
import ManageReviewsPanel from './manageReviews';
import ManageModeratorsPanel from './manageModerators'
import ManageEventsPanel from './manageEvents';
import WorkflowOrgSetup from './workflowOrgSetup';
import WorkflowAppointments from './workflowAppointments';
import ReportCategories from './reportCategories';
import ReportOrganisationCreation from './reportOrganisationCreation';
import ContactList from './contactList';

function Console({ me, auth, handleLogout, setMe }) {
  const [consoleNav, setConsoleNav] = useState('myProfile');
  const [consoleNavOpen, setConsoleNavOpen] = useState(false);

  if (!me?._id || !auth?.api?.access_token) {
    return (<Redirect to="/" />);
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - console</title>
        <meta
          name='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <GridContainer style={{
        background: paletteData.primary.standard.background,
        height: 80,
      }}>
        <Hidden breakpoint='hiddengreaterthan800'>
          <GridCell center={true} style={{ marginLeft: 10 }}>
            <Fab
              size='small'
              palette='secondary'
              onClick={() => setConsoleNavOpen(true)}
            >
              <span className="material-icons md-24">menu</span>
            </Fab>
          </GridCell>
        </Hidden>
        <GridCell weight={1} center centerWeights={{ top: 1, bottom: 2 }} style={{ textAlign: 'center' }}>
          <Typography size='heading' style={{ color: paletteData.primary.standard.foreground, textShadow: '2px 2px black' }}>
            My Console
          </Typography>
        </GridCell>
      </GridContainer>
      <GridContainer style={{ flex: 1, flexWrap: 'nowrap' }}>
        <Hidden breakpoint='hiddenlessthan800'>
          <GridCell
            style={{
              background: '#c6c6c6',
              padding: 10,
            }}
          >
            <ConsoleMenuPanel selected={consoleNav} handleChange={(value) => {
              setConsoleNav(value);
              window.scrollTo(0, 0);
            }} me={me} />
          </GridCell>
        </Hidden>
        <GridCell
          weight={4}
          style={{ padding: 10, display: 'flex', flexDirection: 'column' }}
        >
          {consoleNav === 'myProfile' &&
            <MyProfilePanel me={me} auth={auth} handleLogout={handleLogout} />
          }
          {consoleNav === 'manageUsers' &&
            <ManageUsersPanel me={me} auth={auth} />
          }
          {consoleNav === 'manageOrganisations' &&
            <ManageOrganisationsPanel me={me} auth={auth} setMe={setMe} />
          }
          {consoleNav === 'manageAreas' &&
            <ManageAreasPanel me={me} auth={auth} setMe={setMe} />
          }
          {consoleNav === 'ManageCategories' &&
            <ManageCategoriesPanel me={me} auth={auth} />
          }
          {consoleNav === 'manageProducts' &&
            <ManageProductsPanel me={me} auth={auth} />
          }
          {consoleNav === 'manageOrders' &&
            <ManageOrdersPanel me={me} auth={auth} />
          }
          {consoleNav === 'manageReviews' &&
            <ManageReviewsPanel me={me} auth={auth} />
          }
          {consoleNav === 'workflowOrgSetup' &&
            <WorkflowOrgSetup me={me} auth={auth} />
          }
          {consoleNav === 'manageBookingCalendars' &&
            <ManageBookingCalendarsPanel me={me} auth={auth} />
          }
          {consoleNav === 'workflowAppointments' &&
            <WorkflowAppointments me={me} auth={auth} />
          }
          {consoleNav === 'manageModerators' &&
            <ManageModeratorsPanel me={me} auth={auth} />
          }
          {consoleNav === 'reportCategories' &&
            <ReportCategories me={me} auth={auth} />
          }
          {consoleNav === 'manageEvents' &&
            <ManageEventsPanel me={me} auth={auth} />
          }
          {consoleNav === 'reportOrganisationCreation' &&
            <ReportOrganisationCreation me={me} auth={auth} />
          }
          {consoleNav === 'contactList' &&
            <ContactList me={me} auth={auth} />
          }
        </GridCell>
      </GridContainer>

      {/*popouts and popups*/}
      <MenuSide style={{ background: '#c6c6c6' }} open={consoleNavOpen} handleClose={() => setConsoleNavOpen(false)}>
        <div style={{ margin: 5 }}>
          <ConsoleMenuPanel selected={consoleNav} handleChange={(value) => { setConsoleNav(value); setConsoleNavOpen(false) }} me={me} />
        </div>
      </MenuSide>
    </div>
  );
}

Console.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

export default Console;
