import React, { useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { connect } from 'react-redux';

import SearchAreas from './SearchAreas';
import EditAreaPreview from './EditAreaPreview';
import EditAreaLayout from './EditAreaLayout';
import EditAreaGallery from './EditAreaGallery';
import EditAreaVideoLibrary from './EditAreaVideoLibrary';
import EditAreaInfo from './EditAreaInfo';

import { GridContainer, GridCell } from '../../../../components/grid';
import { paletteData, Button, ProgressBar, Dialog, Typography } from '../../../../components/styles';
import { setAreas, addAreas, addArea, replaceArea, removeArea, setSearch } from '../../../../store/reducers/lists/actions.js';
import { setAreaReference, clearAreaReference, setUserReferences } from '../../../../store/reducers/references/actions.js';
import { setApiToken } from '../../../../store/reducers/auth/actions.js';
import Hidden from '../../../../components/hidden';

import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageAreasPanel({
  me,
  auth,
  areas,
  search,
  setAreas,
  addAreas,
  addArea,
  replaceArea,
  removeArea,
  setAreaReference,
  clearAreaReference,
  setSearch,
  queryLimit,
  setApiToken,
  setMe,

  users,
  userReferences,
  setUserReferences,
}) {
  // page state
  const [focusedArea, setFocusedArea] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [focusedSubSection, setFocusedSubSection] = useState('preview');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangesFocusedArea = (changes) => {
    setFocusedArea({
      ...focusedArea,
      ...changes,
    });
  }

  const handleChangeFocusedArea = (name, value) => {
    setFocusedArea({
      ...focusedArea,
      [name]: value,
    });
  };

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({ type: 'post', action: 'upload/image', data: { file: reader.result } }).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({ imageId: result.data.public_id, alt }, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleUpdateArea = () => {
    setProcessing(true);
    const areaId = focusedArea._id;
    const updateArea = {
      hidden: focusedArea?.hidden,
      name: focusedArea?.name,
      nameProper: focusedArea?.nameProper,
      tagline: focusedArea?.tagline,
      blurb: focusedArea?.blurb,
      thumbnail: focusedArea?.thumbnail,
      gallery: focusedArea?.gallery,
      videoLibrary: focusedArea?.videoLibrary,
      position: focusedArea?.position,
      layout: focusedArea?.layout,
      moderators: focusedArea?.moderators,
      humanId: focusedArea?.humanId,
      community: focusedArea?.community || {},
    };
    if (areaId === 'new') {
      apiRequest({ type: 'post', action: `areas/create`, data: { updateArea } })
        .then((result) => {
          addArea(result.data.area);
          setAreaReference(result.data.area);
          setProcessing(false);
          setFocusedArea(undefined);
          setFocusedSubSection('preview');
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Create area request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
    } else {
      apiRequest({ type: 'patch', action: `areas/update/${areaId}`, data: { updateArea } })
        .then((result) => {
          if (result.data.auth) {
            setApiToken(result.data.auth);
            setMe({
              ...me,
              ...(jwt.decode(result.data.auth?.access_token))?.data,
            });
          }
          replaceArea({ area: { _id: areaId, ...updateArea } });
          setAreaReference({ _id: areaId, ...updateArea });
          setProcessing(false);
          setFocusedArea(undefined);
          setFocusedSubSection('preview');
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Update area request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
    }
  }

  const handleDeleteArea = () => {
    setProcessing(true);
    const areaId = focusedArea._id || 'new';
    apiRequest({ type: 'delete', action: `areas/delete/${areaId}` })
      .then((result) => {
        setDialogOpen(false);
        setDialogData(undefined);
        setProcessing(false);

        removeArea({ area: focusedArea });
        clearAreaReference(areaId);
        setFocusedArea(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Delete area request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{ background: paletteData.primary.standard.background, padding: 10 }}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{ top: 1, bottom: 2 }} style={{ height: 45 }}>
            <div style={{ color: paletteData.primary.standard.foreground, fontSize: '1.5em' }}>
              {focusedArea ? ` ${focusedArea.name || 'unnamed area'}` : 'Areas'}
            </div>
          </GridCell>
          {/*large buttons*/}
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell center style={{ height: 45, display: 'block' }}>
              <Button
                disabled={processing}
                palette="secondary"
                onClick={() => {
                  if (focusedArea) {
                    setFocusedArea(undefined);
                    setFocusedSubSection('preview');
                  } else {
                    setFocusedArea({ _id: 'new', gallery: [], videoLibrary: [] });
                  }
                }}
              >
                {focusedArea ? 'Cancel' : 'Add'}
              </Button>
            </GridCell>
          </Hidden>
          {me.roles?.global && me.roles.global['super-admin'] &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{ marginLeft: 5, height: 45, display: focusedArea && focusedArea._id !== 'new' ? 'block' : 'none' }}>
                <Button palette='secondary' onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'deleteArea',
                    title: 'Delete Area',
                    message: 'Are you sure you wish to delete this area?  This is irreversible',
                  });
                }} disabled={processing}>
                  Delete Area
                </Button>
              </GridCell>
            </Hidden>
          }

          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell center style={{ marginLeft: 5, height: 45, display: focusedArea ? 'block' : 'none' }}>
              <Button
                palette='secondary'
                onClick={() => handleUpdateArea()}
                disabled={processing || !focusedArea?.name}
              >
                {focusedArea?._id === 'new' ? 'Create' : 'Update'} Area
              </Button>
              {processing &&
                <ProgressBar palette='secondary' />
              }
            </GridCell>
          </Hidden>

          {/*small buttons*/}
          <Hidden breakpoint='hiddengreaterthan650'>
            <GridCell center style={{ height: 45, display: 'block' }}>
              <Button
                size='small'
                disabled={processing}
                palette="secondary"
                onClick={() => {
                  if (focusedArea) {
                    setFocusedArea(undefined);
                    setFocusedSubSection('preview');
                  } else {
                    setFocusedArea({ _id: 'new', gallery: [], videoLibrary: [] });
                  }
                }}
              >
                {focusedArea ? 'Cancel' : 'Add'}
              </Button>
            </GridCell>
          </Hidden>
          {me.roles?.global && me.roles.global['super-admin'] &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{ marginLeft: 5, height: 45, display: focusedArea && focusedArea._id !== 'new' ? 'block' : 'none' }}>
                <Button
                  size='small'
                  palette='secondary'
                  disabled={processing}
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogData({
                      type: 'deleteArea',
                      title: 'Delete Area',
                      message: 'Are you sure you wish to delete this area?  This is irreversible',
                    });
                  }}
                >
                  Delete Area
                </Button>
              </GridCell>
            </Hidden>
          }
          <Hidden breakpoint='hiddengreaterthan650'>
            <GridCell center style={{ marginLeft: 5, height: 45, display: focusedArea ? 'block' : 'none' }}>
              <Button
                size='small'
                palette='secondary'
                disabled={processing || !focusedArea?.name}
                onClick={() => handleUpdateArea()}
              >
                {focusedArea?._id === 'new' ? 'Create' : 'Update'} Area
              </Button>
              {processing &&
                <ProgressBar palette='secondary' />
              }
            </GridCell>
          </Hidden>
        </GridContainer>
      </div>
      {!focusedArea &&
        <SearchAreas
          areas={areas}
          addAreas={addAreas}
          setAreas={setAreas}
          search={search}
          setSearch={setSearch}
          queryLimit={queryLimit}
          processing={processing}
          setProcessing={setProcessing}
          setFocusedArea={setFocusedArea}
          userReferences={userReferences}
          setUserReferences={setUserReferences}
        />
      }
      {focusedArea &&
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {(!focusedArea?.name) &&
            <div style={{ padding: 10, margin: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'red' }}>
              <Typography size='title' style={{ color: 'red' }}>
                Required information is missing:
              </Typography>
              {!focusedArea?.name &&
                <Typography style={{ color: 'red' }}>
                  Preview - Name
                </Typography>
              }
            </div>
          }
          <GridContainer style={{ borderStyle: 'none none solid none', marginBottom: 10, marginTop: 10, flexWrap: 'nowrap' }}>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'preview' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('preview')}>
              Preview
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'layout' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('layout')}>
              Layout
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'info' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('info')}>
              Information
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'gallery' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('gallery')}>
              Gallery
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'video' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('video')}>
              Videos
            </GridCell>
          </GridContainer>
          {focusedSubSection === 'preview' &&
            <EditAreaPreview
              focusedArea={focusedArea}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedArea={handleChangeFocusedArea}
              handleChangesFocusedArea={handleChangesFocusedArea}
              handleUploadImage={handleUploadImage}
            />
          }
          {focusedSubSection === 'layout' &&
            <EditAreaLayout
              me={me}
              focusedArea={focusedArea}
              handleChangeFocusedArea={handleChangeFocusedArea}
              processing={processing}
              setProcessing={setProcessing}
            />
          }
          {focusedSubSection === 'gallery' &&
            <EditAreaGallery
              focusedArea={focusedArea}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedArea={handleChangeFocusedArea}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'video' &&
            <EditAreaVideoLibrary
              focusedArea={focusedArea}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedArea={handleChangeFocusedArea}
              handleUploadImage={handleUploadImage}
            />
          }
          {focusedSubSection === 'info' &&
            <EditAreaInfo
              focusedArea={focusedArea}
              processing={processing}
              handleChangeFocusedArea={handleChangeFocusedArea}
              setProcessing={setProcessing}
              users={users}
              userReferences={userReferences}
              setUserReferences={setUserReferences}
            />
          }
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
            setDialogData(undefined);
            setDialogOpen(false);
          }}
        >
          <div style={{ padding: 10, textAlign: 'center', background: paletteData.primary.standard.background }}>
            <Typography size='title' style={{ color: paletteData.primary.standard.foreground }}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{ padding: 10 }}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1} />
            <GridCell style={{ padding: 10 }}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'deleteArea' &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => handleDeleteArea()}
                >
                  Delete Area
                </Button>
                {processing &&
                  <ProgressBar />
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageAreasPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  areas: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (state) => {
  return {
    areas: state.lists.areas,
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
    users: state.lists.users,
    userReferences: state.references.users || {},
  };
};

export default connect(mapStateToProps, { setAreas, addAreas, addArea, replaceArea, removeArea, setAreaReference, clearAreaReference, setSearch, setApiToken, setUserReferences })(ManageAreasPanel);
