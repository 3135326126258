import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {paletteData, Button, Typography, Dialog, ProgressBar} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import CategoryForm from '../../../../../components/forms/category';
import SearchStore from '../../../../../components/search/store';

function EditCategoryInfoSubPanel({
    focusedCategory,
    handleChangeFocusedCategory,
    processing,
    setProcessing,
    moderatorsLoaded,
    search,
    users,
    userReferences,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <CategoryForm
        category={focusedCategory}
        handleChange={(name, value) => {
          handleChangeFocusedCategory(name, value);
        }}
      />
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center={true}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Category Moderators
            </Typography>
          </GridCell>
          <GridCell>
            <Button size='small' palette='secondary' onClick={() => {
              setDialogData({
                type: 'addModerator',
                title: 'Add Category Moderator',
                data: {
                  expandSearch: false,
                }
              });
              setDialogOpen(true);
            }}>
              Add Moderator
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {!moderatorsLoaded &&
        <div style={{padding: 10}}>
          <Typography>
            ...loading
          </Typography>
          <ProgressBar palette='secondary'/>
        </div>
      }
      {moderatorsLoaded &&
        <div>
          {focusedCategory.moderators?.length > 0 && focusedCategory.moderators.map((m, mIndex) => (
            <GridContainer key={mIndex} style={{padding: 10}}>
              <GridCell weight={1} center={true}>
                <Typography>
                  {userReferences[m]?.name || '...loading'}
                </Typography>
              </GridCell>
              <GridCell>
                <Button size='small' palette='primary' onClick={() => {
                  const  moderatorsTemp = focusedCategory.moderators || [];
                  moderatorsTemp.splice(mIndex, 1);
                  handleChangeFocusedCategory('moderators', moderatorsTemp);
                }}>
                  remove
                </Button>
              </GridCell>

            </GridContainer>
          ))}
          {(!focusedCategory.moderators || focusedCategory.moderators.length === 0) &&
            <div style={{padding: 10}}>
              <Typography>
                No Community Moderators Assigned
              </Typography>
            </div>
          }
        </div>
      }
      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
       >
         <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
           <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
             {dialogData?.title}
           </Typography>
         </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'addModerator' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='users'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData.data.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    data: {
                      ...(dialogData.data || {}),
                      expandSearch: value,
                    }
                  })
                }}
              />
              {users?.length > 0 && users.map((u, uIndex) => (
                <div
                  key={uIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, cursor: 'pointer'}}
                  onClick={() => {
                    const  moderatorsTemp = focusedCategory.moderators || [];
                    moderatorsTemp.push(u._id);
                    handleChangeFocusedCategory('moderators', moderatorsTemp);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  {u.name}
                </div>
              ))}
              {users?.length > 5 && search?.users?.hasMore &&
                <div style={{padding: 5}}>
                  <Button
                    disabled={processing}
                    palette='primary'
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        data: {
                          ...(dialogData.data || {}),
                          expandSearch: true,
                        }
                      })
                    }}>
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditCategoryInfoSubPanel.propTypes = {
  focusedCategory: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedCategory: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditCategoryInfoSubPanel;
