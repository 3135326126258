import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../styles';
import { GridContainer, GridCell } from '../../grid';

function CategoryForm({ category, handleChange }) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="name"
            palette='secondary'
            label="Name - Safe Search"
            type="text"
            value={category?.name || ''}
            onChange={(value) => handleChange('name', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="nameProper"
            palette='secondary'
            label="Name - Proper"
            type="text"
            value={category?.nameProper || ''}
            onChange={(value) => handleChange('nameProper', value)}
          />
        </GridCell>
      </GridContainer>
      <Input
        name="humanId"
        palette='secondary'
        label="Human Readable Id"
        type="text"
        value={category?.humanId || ''}
        onChange={(value) => handleChange('humanId', value)}
      />
    </div>
  );
}

CategoryForm.propTypes = {
  category: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default CategoryForm;
