import React from 'react';
import PropTypes from 'prop-types';

import {paletteData, Typography} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function ConsoleMenuPanel({selected, handleChange, me}) {
  const organisationalRolesTest = () => {
    const organisationalRoles = {};
    const organisationalKeys = Object.keys(me?.roles?.organisational || {});
    for(let i = 0; i < organisationalKeys.length; i += 1) {
      if(me.roles?.organisational[organisationalKeys]?.owner) {
        organisationalRoles.owner = true;
      }
    }
    return organisationalRoles;
  }
  const organisationalRoles = organisationalRolesTest();

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10, width: 200}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Administration
            </Typography>
          </GridCell>
        </GridContainer>
      </div>
      <div style={{padding: 10}}>
        <Typography style={{cursor: 'pointer', color: selected === 'myProfile' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('myProfile')}>My Profile</Typography>
        {(me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker'])) &&
          <Typography style={{cursor: 'pointer', color: selected === 'manageUsers' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('manageUsers')}>Manage Users</Typography>
        }
         {(me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker'])) &&
          <Typography style={{cursor: 'pointer', color: selected === 'contactList' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('contactList')}>Contact List</Typography>
        }
        <br/>
      </div>
      {(organisationalRoles.owner || (me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
        <div style={{background: paletteData.primary.standard.background, padding: 10, width: 200}}>
          <GridContainer>
            <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                Workflows
              </Typography>
            </GridCell>
          </GridContainer>
        </div>
      }
      {(organisationalRoles.owner || (me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
        <div style={{padding: 10}}>
          <Typography style={{cursor: 'pointer', color: selected === 'workflowOrgSetup' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('workflowOrgSetup')}>Organisation Setup</Typography>
          <br/>
        </div>
      }
      {(organisationalRoles.owner || (me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
        <div style={{background: paletteData.primary.standard.background, padding: 10, width: 200}}>
          <GridContainer>
            <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                Listings
              </Typography>
            </GridCell>
          </GridContainer>
        </div>
      }
      {(organisationalRoles.owner || (me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
        <div style={{padding: 10}}>
          {(me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker'])) &&
            <Typography style={{cursor: 'pointer', color: selected === 'manageAreas' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('manageAreas')}>Manage Areas</Typography>
          }
          {(me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker'])) &&
            <Typography style={{cursor: 'pointer', color: selected === 'ManageCategories' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('ManageCategories')}>Manage Groups</Typography>
          }
          <Typography style={{cursor: 'pointer', color: selected === 'manageOrganisations' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('manageOrganisations')}>Manage Organisations</Typography>
          <br/>
        </div>
      }
      {(me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'])) &&
        <div>
          <div style={{background: paletteData.primary.standard.background, padding: 10, width: 200}}>
            <GridContainer>
              <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
                <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                  Community
                </Typography>
              </GridCell>
            </GridContainer>
          </div>
          <div style={{padding: 10}}>
            <Typography style={{cursor: 'pointer', color: selected === 'manageReviews' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('manageReviews')}>Manage Reviews</Typography>
            <br/>
          </div>
        </div>
      }
      {(me.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'])) &&
        <div>
          <div style={{background: paletteData.primary.standard.background, padding: 10, width: 200}}>
            <GridContainer>
              <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
                <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                  Reports
                </Typography>
              </GridCell>
            </GridContainer>
          </div>
          <div style={{padding: 10}}>
            <Typography style={{cursor: 'pointer', color: selected === 'reportCategories' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('reportCategories')}>Category Report</Typography>
            <Typography style={{cursor: 'pointer', color: selected === 'reportOrganisationCreation' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('reportOrganisationCreation')}>Organisation Creation Report</Typography>
            <br/>
          </div>
        </div>
      }
    </div>
  );
}

ConsoleMenuPanel.propTypes = {
  selected: PropTypes.string,
  handleChange: PropTypes.func,
  me: PropTypes.shape({}),
}

export default ConsoleMenuPanel;
