import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EditOrganisationPreview from './EditOrganisationPreview';
import EditOrganisationGallery from './EditOrganisationGallery';
import EditOrganisationVideoLibrary from './EditOrganisationVideoLibrary';
import EditOrganisationLayout from './EditOrganisationLayout';
import EditOrganisationInfo from './EditOrganisationInfo';
import EditOrganisationOffices from './EditOrganisationOffices';
// import EditOrganisationMailingList from './EditOrganisationMailingList';
import SearchOrganisations from './SearchOrganisations';

import { GridContainer, GridCell } from '../../../../components/grid';
import { paletteData, Button, ProgressBar, Dialog, Typography } from '../../../../components/styles';
import Hidden from '../../../../components/hidden';

import apiRequest from '../../../../tools/apiRequest';
import { setOrganisations, addOrganisations, addOrganisation, replaceOrganisation, removeOrganisation, setSearch, setUsers, addUsers, setAreas, addAreas, setCategories, addCategories } from '../../../../store/reducers/lists/actions.js';
import { setOrganisationReference, clearOrganisationReference, setOrganisationReferences, setUserReference, setAreaReferences, setAreaReference } from '../../../../store/reducers/references/actions.js';
import { setApiToken } from '../../../../store/reducers/auth/actions.js';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageOrganisationsPanel({
  me,
  auth,
  setApiToken,

  setSearch,
  search,
  queryLimit,

  categories,

  users,
  userReferences,
  setUserReference,

  areas,
  areaReferences,
  setAreaReference,
  setAreaReferences,

  organisations,
  setOrganisationReference,
  setOrganisationReferences,
  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,

  clearOrganisationReference,
}) {
  // page state
  const [focusedOrganisation, setFocusedOrganisation] = useState(undefined);
  const [focusedOrganisationOriginalOwner, setFocusedOrganisationOriginalOwner] = useState(undefined);
  const [focusedSubSection, setFocusedSubSection] = useState('preview');

  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const [focusedArea, setFocusedArea] = useState(search?.organisations?.query && search?.organisations?.query['offices.area'] && areaReferences[search?.organisations?.query['offices.area']] ? areaReferences[search?.organisations?.query['offices.area']] : undefined);

  const handleChangesFocusedOrganisation = (changes) => {
    setFocusedOrganisation({
      ...focusedOrganisation,
      ...changes,
    });
  }

  const handleChangeFocusedOrganisation = (name, value) => {
    setFocusedOrganisation({
      ...focusedOrganisation,
      [name]: value,
    });
  };

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({ type: 'post', action: 'upload/image', data: { file: reader.result } }).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({ imageId: result.data.public_id, alt }, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleUpdateOrganisation = () => {
    setProcessing(true);
    const organisationId = focusedOrganisation._id;
    const updateOrganisation = {
      hidden: focusedOrganisation?.hidden,
      owner: focusedOrganisation?.owner || me._id,
      type: 'organisation',

      name: focusedOrganisation?.name,
      nameProper: focusedOrganisation?.nameProper,
      humanId: focusedOrganisation?.humanId,
      tagline: focusedOrganisation?.tagline,
      blurb: focusedOrganisation?.blurb,
      categories: focusedOrganisation?.categories,

      logo: focusedOrganisation?.logo,
      thumbnail: focusedOrganisation?.thumbnail,
      splash: focusedOrganisation?.splash,
      heading: focusedOrganisation?.heading,
      gallery: focusedOrganisation?.gallery,
      videoLibrary: focusedOrganisation?.videoLibrary,

      layout: focusedOrganisation?.layout,
      
      website: focusedOrganisation?.website,
      social: focusedOrganisation?.social,
      healthAndSafety: focusedOrganisation?.healthAndSafety,
      email: focusedOrganisation?.email,
      phone: focusedOrganisation?.phone,
      gst: focusedOrganisation?.gst,
      bank: focusedOrganisation?.bank,
      bankHidden: focusedOrganisation?.bankHidden,
      stripe: focusedOrganisation?.stripe,
      stripeHidden: focusedOrganisation?.stripeHidden,
      shopEnabled: focusedOrganisation?.shopEnabled,
      offices: focusedOrganisation?.offices,
    }
    if (organisationId === 'new') {
      apiRequest({ type: 'post', action: `organisations/create`, data: { updateOrganisation } })
        .then((result) => {
          if (result.data.auth) {
            setApiToken(result.data.auth);
          }
          if (userReferences[updateOrganisation.owner]) {
            const tempUserReference = userReferences[updateOrganisation.owner];
            if (!tempUserReference.roles) { tempUserReference.roles = {} };
            if (!tempUserReference.roles.organisational) { tempUserReference.roles.organisational = {} };
            tempUserReference.roles.organisational[result.data.organisation._id] = { owner: true };
            setUserReference(tempUserReference);
          }
          addOrganisation(result.data.organisation);
          setOrganisationReference(result.data.organisation);
          setProcessing(false);
          setFocusedOrganisation(undefined);
          setFocusedSubSection('preview');
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Update organisation request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
    } else {
      apiRequest({ type: 'patch', action: `organisations/update/${organisationId}`, data: { updateOrganisation } })
        .then((result) => {
          if (focusedOrganisation?.owner === me._id) {
            if (result.data.auth) {
              setApiToken(result.data.auth);
            }
          }
          if (userReferences[focusedOrganisationOriginalOwner]) {
            const tempUserReference = userReferences[focusedOrganisationOriginalOwner];
            if (tempUserReference.roles?.organisational && tempUserReference.roles.organisational[organisationId]) {
              delete tempUserReference.roles.organisational[organisationId];
              setUserReference(tempUserReference);
            }
          }
          if (userReferences[updateOrganisation.owner]) {
            const tempUserReference = userReferences[updateOrganisation.owner];
            if (!tempUserReference.roles) { tempUserReference.roles = {} };
            if (!tempUserReference.roles.organisational) { tempUserReference.roles.organisational = {} };
            tempUserReference.roles.organisational[organisationId] = { owner: true };
            setUserReference(tempUserReference);
          }
          replaceOrganisation({ organisation: { _id: organisationId, ...updateOrganisation } });
          setOrganisationReference({ _id: organisationId, ...updateOrganisation });
          setProcessing(false);
          setFocusedOrganisation(undefined);
          setFocusedOrganisationOriginalOwner(undefined);
          setFocusedSubSection('preview');
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Update organisation request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
    }
  }

  const handleDeleteOrganisation = () => {
    setProcessing(true);
    const organisationId = focusedOrganisation._id || 'new';
    apiRequest({ type: 'delete', action: `organisations/delete/${organisationId}` })
      .then((result) => {
        setDialogOpen(false);
        setDialogData(undefined);
        setProcessing(false);

        if (focusedOrganisation?.owner === me._id) {
          if (result.data.auth) {
            setApiToken(result.data.auth);
          }
        }
        removeOrganisation({ organisation: focusedOrganisation });
        clearOrganisationReference(organisationId);
        setFocusedOrganisation(undefined);
        setFocusedOrganisationOriginalOwner(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setDialogOpen(false);
        setDialogData(undefined);
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Delete organisation request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
  };

  const handleVerifyEmail = (email) => {
    setProcessing(true);
    const updateKey = {
      name: me?.name,
      userId: me?._id,
      type: 'emailverification',
      container: 'systems',
      value: email,
    }
    apiRequest({ type: 'post', action: `keys/create`, data: { updateKey } })
      .then((result) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Email Verification Sent',
          message: 'Please check your emails and follow the instructions to verify this email address',
        });
        setDialogOpen(true);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Verify email request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
  };

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{ background: paletteData.primary.standard.background, padding: 10 }}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{ top: 1, bottom: 2 }} style={{ height: 45 }}>
            <div style={{ color: paletteData.primary.standard.foreground, fontSize: '1.5em' }}>
              {focusedOrganisation ? ` ${focusedOrganisation.nameProper || focusedOrganisation.name || 'unnamed organisation'}` : 'Organisations'}
            </div>
          </GridCell>
          {/*large buttons*/}
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{ height: 45, display: focusedOrganisation ? 'none' : 'block' }}>
                <Button
                  disabled={processing}
                  palette="secondary"
                  onClick={() => {
                    if (!userReferences[me._id]) {
                      setUserReference(me);
                    }
                    setFocusedOrganisation({ _id: 'new', owner: me._id, gallery: [], videoLibrary: [], offices: [{ address: {}, staff: [] }] });
                  }}
                >
                  Add
                </Button>
              </GridCell>
            </Hidden>
          }
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell center style={{ height: 45, display: focusedOrganisation ? 'block' : 'none' }}>
              <Button
                disabled={processing}
                palette="secondary"
                onClick={() => {
                  if (focusedOrganisation) {
                    setFocusedOrganisation(undefined);
                    setFocusedOrganisationOriginalOwner(undefined);
                    setFocusedSubSection('preview');
                  }
                }}
              >
                Cancel
              </Button>
            </GridCell>
          </Hidden>
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{ marginLeft: 5, height: 45, display: focusedOrganisation && focusedOrganisation?._id !== 'new' ? 'block' : 'none' }}>
                <Button palette='secondary' onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'deleteOrganisation',
                    title: 'Delete Organisation',
                    message: 'Are you sure you wish to delete this organisation?  This is irreversible',
                  });
                }} disabled={processing}>
                  Delete Organisation
                </Button>
              </GridCell>
            </Hidden>
          }
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell center style={{ marginLeft: 5, height: 45, display: focusedOrganisation ? 'block' : 'none' }}>
              <Button
                palette='secondary'
                onClick={() => handleUpdateOrganisation()}
                disabled={processing || !focusedOrganisation?.name}
              >
                {focusedOrganisation?._id === 'new' ? 'Create' : 'Update'} Organisation
              </Button>
              {processing &&
                <ProgressBar palette='secondary' />
              }
            </GridCell>
          </Hidden>

          {/*small buttons*/}
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{ height: 45, display: focusedOrganisation ? 'none' : 'block' }}>
                <Button
                  size='small'
                  disabled={processing}
                  palette="secondary"
                  onClick={() => {
                    if (!userReferences[me._id]) {
                      setUserReference(me);
                    }
                    setFocusedOrganisation({ _id: 'new', owner: me._id, gallery: [], videoLibrary: [], offices: [{ address: {}, staff: [] }] });
                  }}
                >
                  Add
                </Button>
              </GridCell>
            </Hidden>
          }
          <Hidden breakpoint='hiddengreaterthan650'>
            <GridCell center style={{ height: 45, display: focusedOrganisation ? 'block' : 'none' }}>
              <Button
                size='small'
                disabled={processing}
                palette="secondary"
                onClick={() => {
                  if (focusedOrganisation) {
                    setFocusedOrganisation(undefined);
                    setFocusedOrganisationOriginalOwner(undefined);
                    setFocusedSubSection('preview');
                  }
                }}
              >
                Cancel
              </Button>
            </GridCell>
          </Hidden>
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{ marginLeft: 5, height: 45, display: focusedOrganisation && focusedOrganisation?._id !== 'new' ? 'block' : 'none' }}>
                <Button
                  size='small'
                  palette='secondary'
                  disabled={processing}
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogData({
                      type: 'deleteOrganisation',
                      title: 'Delete Organisation',
                      message: 'Are you sure you wish to delete this organisation?  This is irreversible',
                    });
                  }}
                >
                  Delete
                </Button>
              </GridCell>
            </Hidden>
          }
          <Hidden breakpoint='hiddengreaterthan650'>
            <GridCell center style={{ marginLeft: 5, height: 45, display: focusedOrganisation ? 'block' : 'none' }}>
              <Button
                size='small'
                palette='secondary'
                onClick={() => handleUpdateOrganisation()}
                disabled={processing || !focusedOrganisation?.name}
              >
                {focusedOrganisation?._id === 'new' ? 'Create' : 'Update'}
              </Button>
              {processing &&
                <ProgressBar palette='secondary' />
              }
            </GridCell>
          </Hidden>
        </GridContainer>
      </div>
      {!focusedOrganisation &&
        <div>
          <SearchOrganisations
            me={me}
            organisations={organisations}
            setOrganisations={setOrganisations}
            addOrganisations={addOrganisations}
            search={search}
            setSearch={setSearch}
            queryLimit={queryLimit}
            processing={processing}
            setProcessing={setProcessing}
            setFocusedOrganisation={setFocusedOrganisation}
            setFocusedOrganisationOriginalOwner={setFocusedOrganisationOriginalOwner}
            handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
            users={users}
            areas={areas}
            userReferences={userReferences}
            areaReferences={areaReferences}
            setUserReference={setUserReference}
            setAreaReferences={setAreaReferences}

            setOrganisationReferences={setOrganisationReferences}
            focusedArea={focusedArea}
            setFocusedArea={setFocusedArea}
            setAreaReference={setAreaReference}
          />
        </div>
      }
      {focusedOrganisation &&
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {(!focusedOrganisation?.name) &&
            <div style={{ padding: 10, margin: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'red' }}>
              <Typography size='title' style={{ color: 'red' }}>
                Required information is missing:
              </Typography>
              {!focusedOrganisation?.name &&
                <Typography style={{ color: 'red' }}>
                  Preview - Name
                </Typography>
              }
            </div>
          }
          <GridContainer style={{ borderStyle: 'none none solid none', marginBottom: 10, marginTop: 10, flexWrap: 'nowrap' }}>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'preview' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('preview')}>
              Preview
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'layout' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('layout')}>
              Layout
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'info' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('info')}>
              Info
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'offices' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('offices')}>
              Offices
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'gallery' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('gallery')}>
              Gallery
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'video' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('video')}>
              Videos
            </GridCell>
          </GridContainer>
          {/*focusedSubSection === 'registry' &&
            <EditOrganisationMailingList
              me={me}
              focusedOrganisation={focusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
            />
          */}
          {focusedSubSection === 'preview' &&
            <EditOrganisationPreview
              focusedOrganisation={focusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
              handleChangesFocusedOrganisation={handleChangesFocusedOrganisation}
              handleUploadImage={handleUploadImage}
            />
          }
          {focusedSubSection === 'gallery' &&
            <EditOrganisationGallery
              focusedOrganisation={focusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'video' &&
            <EditOrganisationVideoLibrary
              focusedOrganisation={focusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'layout' &&
            <EditOrganisationLayout
              me={me}
              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
            />
          }
          {focusedSubSection === 'info' &&
            <EditOrganisationInfo
              me={me}
              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
              handleUploadImage={handleUploadImage}
              users={users}
              userReferences={userReferences}
              setUserReference={setUserReference}
              setUsers={setUsers}
              addUsers={addUsers}
              categories={categories}
              setCategories={setCategories}
              addCategories={addCategories}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}
            />
          }
          {focusedSubSection === 'offices' &&
            <EditOrganisationOffices
              me={me}
              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
              handleVerifyEmail={handleVerifyEmail}
              processing={processing}
              handleUploadImage={handleUploadImage}
              setProcessing={setProcessing}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}
              areas={areas}
              setAreas={setAreas}
              addAreas={addAreas}
              areaReferences={areaReferences}
              setAreaReference={setAreaReference}
            />
          }
        </div>
      }

      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{ padding: 10, textAlign: 'center', background: paletteData.primary.standard.background }}>
            <Typography size='title' style={{ color: paletteData.primary.standard.foreground }}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{ padding: 10 }}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1} />
            <GridCell style={{ padding: 10 }}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'deleteOrganisation' &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => handleDeleteOrganisation()}
                >
                  Delete Organisation
                </Button>
                {processing &&
                  <ProgressBar />
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageOrganisationsPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  setApiToken: PropTypes.func,

  setSearch: PropTypes.func,
  search: PropTypes.shape({}),

  users: PropTypes.arrayOf(PropTypes.shape()),

  categories: PropTypes.arrayOf(PropTypes.shape()),
  setCategories: PropTypes.func,
  addCategories: PropTypes.func,

  userReferences: PropTypes.shape({}),
  setUsers: PropTypes.func,
  addUsers: PropTypes.func,
  setUserReference: PropTypes.func,

  areas: PropTypes.arrayOf(PropTypes.shape()),
  areaReferences: PropTypes.shape({}),
  setAreaReference: PropTypes.func,
  setAreas: PropTypes.func,
  addAreas: PropTypes.func,

  organisations: PropTypes.arrayOf(PropTypes.shape()),
  setOrganisationReference: PropTypes.func,
  setOrganisations: PropTypes.func,
  addOrganisations: PropTypes.func,
  addOrganisation: PropTypes.func,
  replaceOrganisation: PropTypes.func,
  removeOrganisation: PropTypes.func,

  clearOrganisationReference: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    organisations: state.lists.organisations,
    search: state.lists.search,
    users: state.lists.users,
    areas: state.lists.areas,
    categories: state.lists.categories,
    userReferences: state.references.users || {},
    areaReferences: state.references.areas || {},
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {
  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
  setOrganisationReference,
  setOrganisationReferences,
  clearOrganisationReference,
  setUserReference,
  setAreaReference,
  setAreaReferences,
  setSearch,
  setApiToken,
  setUsers,
  addUsers,
  setAreas,
  addAreas,
  setCategories,
  addCategories,
})(ManageOrganisationsPanel);
