import React from 'react';

import { Input } from '../../styles';
import { GridContainer, GridCell } from '../../grid';

function AreaDetailsForm({ focusedArea, handleChange }) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="name"
            palette='secondary'
            label="Name - Safe Search"
            type="text"
            value={focusedArea?.name || ''}
            onChange={(value) => handleChange('name', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="nameProper"
            palette='secondary'
            label="Name - Proper"
            type="text"
            value={focusedArea?.nameProper || ''}
            onChange={(value) => handleChange('nameProper', value)}
          />
        </GridCell>
      </GridContainer>
      <Input
        name="humanId"
        palette='secondary'
        label="Human Readable Id"
        type="text"
        value={focusedArea?.humanId || ''}
        onChange={(value) => handleChange('humanId', value)}
      />
    </div>

  );
}

export default AreaDetailsForm;
