import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchCategories from './SearchCategories';
import EditCategoryPreview from './EditCategoryPreview';
import EditCategoryLayout from './EditCategoryLayout';
import EditCategoryGallery from './EditCategoryGallery';
import EditCategoryVideoLibrary from './EditCategoryVideoLibrary';
import EditCategoryInfo from './EditCategoryInfo';

import { GridContainer, GridCell } from '../../../../components/grid';
import { paletteData, Button, ProgressBar, Dialog, Typography } from '../../../../components/styles';
import { setCategories, addCategories, addCategory, replaceCategory, removeCategory } from '../../../../store/reducers/lists/actions.js';
import { setUserReferences } from '../../../../store/reducers/references/actions.js';
import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageCategoriesPanel({
  me,
  auth,
  categories,
  setCategories,
  addCategories,
  addCategory,
  replaceCategory,
  removeCategory,
  search,
  users,
  userReferences,
  setUserReferences
}) {
  console.log(me);
  const [focusedCategory, setFocusedCategory] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [moderatorsLoaded, setModeratorsLoaded] = useState(false);
  const [focusedSubSection, setFocusedSubSection] = useState('preview');

  const handleChangesFocusedCategory = (changes) => {
    setFocusedCategory({
      ...focusedCategory,
      ...changes,
    });
  }

  const handleChangeFocusedCategory = (name, value) => {
    setFocusedCategory({
      ...focusedCategory,
      [name]: value,
    });
  };

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({ type: 'post', action: 'upload/image', data: { file: reader.result } }).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({ imageId: result.data.public_id, alt }, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleUpdateCategory = () => {
    setProcessing(true);
    const categoryId = focusedCategory._id;
    const updateCategory = {
      name: focusedCategory?.name,
      nameProper: focusedCategory?.nameProper,
      tagline: focusedCategory?.tagline,
      blurb: focusedCategory?.blurb,
      thumbnail: focusedCategory?.thumbnail,
      gallery: focusedCategory?.gallery,
      videoLibrary: focusedCategory?.videoLibrary,
      layout: focusedCategory?.layout,
      moderators: focusedCategory?.moderators,
      community: focusedCategory?.community ||
      {
        groups: [{
          name: 'Community Messages',
          postAuthority: 'moderatorValidation',
          visibility: 'public',
          transparency: 'public',
          blackList: {},
          whiteList: {},
        }],
      },
    };
    if (categoryId === 'new') {
      apiRequest({ type: 'post', action: `categories/create`, data: { updateCategory } })
        .then((result) => {
          addCategory(result.data.category);
          setProcessing(false);
          setFocusedCategory(undefined);
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Create event request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
    } else {
      apiRequest({ type: 'patch', action: `categories/update/${categoryId}`, data: { updateCategory } })
        .then((result) => {
          replaceCategory({ category: { _id: categoryId, ...updateCategory } });
          setProcessing(false);
          setFocusedCategory(undefined);
          setModeratorsLoaded(false);
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Create event request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
    }
  }

  const handleDeleteCategory = () => {
    setProcessing(true);
    const categoryId = focusedCategory._id || 'new';
    apiRequest({ type: 'delete', action: `categories/delete/${categoryId}` })
      .then((result) => {
        setProcessing(false);
        removeCategory({ category: focusedCategory });
        setFocusedCategory(undefined);
        setModeratorsLoaded(false);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Create event request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{ background: paletteData.primary.standard.background, padding: 10 }}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{ top: 1, bottom: 2 }} style={{ height: 45 }}>
            <Typography size='title' style={{ color: paletteData.primary.standard.foreground }}>
              Manage Groups
            </Typography>
          </GridCell>
          <GridCell center style={{ height: 45, display: 'block' }}>
            <Button
              disabled={processing}
              palette="secondary"
              onClick={() => {
                if (focusedCategory) {
                  setFocusedCategory(undefined);
                  setModeratorsLoaded(false);
                } else {
                  setFocusedCategory({ _id: 'new' });
                  setModeratorsLoaded(true);
                }
              }}
            >
              {focusedCategory ? 'Cancel' : 'Add'}
            </Button>
          </GridCell>
          {me.roles?.global && me.roles.global['super-admin'] &&
            <GridCell center style={{ marginLeft: 5, height: 45, display: focusedCategory && focusedCategory._id !== 'new' ? 'block' : 'none' }}>
              <Button palette='secondary' onClick={() => handleDeleteCategory()} disabled={processing}>
                Delete Category
              </Button>
            </GridCell>
          }
          <GridCell center style={{ marginLeft: 5, height: 45, display: focusedCategory ? 'block' : 'none' }}>
            <Button palette='secondary' onClick={() => handleUpdateCategory()} disabled={processing}>
              {focusedCategory?._id === 'new' ? 'Create' : 'Update'} Category
            </Button>
            {processing &&
              <ProgressBar palette='secondary' />
            }
          </GridCell>
        </GridContainer>
      </div>
      {!focusedCategory &&
        <SearchCategories
          processing={processing}
          setProcessing={setProcessing}
          categories={categories}
          setFocusedCategory={setFocusedCategory}
          userReferences={userReferences}
          setUserReferences={setUserReferences}
          setModeratorsLoaded={setModeratorsLoaded}
        />
      }
      {focusedCategory &&
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {(!focusedCategory?.name) &&
            <div style={{ padding: 10, margin: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'red' }}>
              <Typography size='title' style={{ color: 'red' }}>
                Required information is missing:
              </Typography>
              {!focusedCategory?.name &&
                <Typography style={{ color: 'red' }}>
                  Preview - Name
                </Typography>
              }
            </div>
          }
          <GridContainer style={{ borderStyle: 'none none solid none', marginBottom: 10, marginTop: 10, flexWrap: 'nowrap' }}>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'preview' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('preview')}>
              Preview
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'layout' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('layout')}>
              Layout
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'info' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('info')}>
              Information
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'gallery' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('gallery')}>
              Gallery
            </GridCell>
            <GridCell style={{ padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'video' ? '#c6c6c6' : '' }} onClick={() => setFocusedSubSection('video')}>
              Videos
            </GridCell>
          </GridContainer>
          {focusedSubSection === 'preview' &&
            <EditCategoryPreview
              focusedCategory={focusedCategory}
              handleChangeFocusedCategory={handleChangeFocusedCategory}
              handleChangesFocusedCategory={handleChangesFocusedCategory}
              handleUploadImage={handleUploadImage}
              processing={processing}
              setProcessing={setProcessing}
            />
          }
          {focusedSubSection === 'layout' &&
            <EditCategoryLayout
              me={me}
              focusedCategory={focusedCategory}
              handleChangeFocusedCategory={handleChangeFocusedCategory}
              processing={processing}
              setProcessing={setProcessing}
            />
          }
          {focusedSubSection === 'info' &&
            <EditCategoryInfo
              focusedCategory={focusedCategory}
              handleChangeFocusedCategory={handleChangeFocusedCategory}
              processing={processing}
              setProcessing={setProcessing}
              moderatorsLoaded={moderatorsLoaded}
              search={search}
              users={users}
              userReferences={userReferences}
            />
          }
          {focusedSubSection === 'gallery' &&
            <EditCategoryGallery
              focusedCategory={focusedCategory}
              handleChangeFocusedCategory={handleChangeFocusedCategory}
              processing={processing}
              setProcessing={setProcessing}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'video' &&
            <EditCategoryVideoLibrary
              focusedCategory={focusedCategory}
              handleChangeFocusedCategory={handleChangeFocusedCategory}
              processing={processing}
              setProcessing={setProcessing}
            />
          }
        </div>
      }

      {/*popouts and popups*/}
      <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <div style={{ padding: 10, textAlign: 'center', background: paletteData.primary.standard.background }}>
          <Typography size='title' style={{ color: paletteData.primary.standard.foreground }}>
            {dialogData?.title}
          </Typography>
        </div>
        {(dialogData?.message) &&
          <Typography>
            {dialogData.message}
          </Typography>
        }

        <GridContainer>
          <GridCell weight={1} />
          <GridCell style={{ padding: 10 }}>
            <Button
              palette='primary'
              onClick={() => {
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
            </Button>
          </GridCell>
        </GridContainer>
      </Dialog>
    </div>
  );
}

ManageCategoriesPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (state) => {
  return {
    categories: state.lists.categories,
    users: state.lists.users,
    userReferences: state.references.users || {},
    search: state.lists.search,
  };
};

export default connect(mapStateToProps, { setCategories, addCategories, addCategory, replaceCategory, removeCategory, setUserReferences })(ManageCategoriesPanel);
